<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <!-- :style="{ maxHeight: '100vh', overflow: 'hidden', overflowX: 'hidden' }" -->
      <div
        :class="['w-100', 'bg-whitesmoke', 'overflow-auto', 'mt-lg-5', 'mt-1']"
        id="form-customer-order"
        ref="formCustomerOrder"
      >
        <div class="card">
          <div class="card-header">
            <h4>Customer Order</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-4">
                <label>Customer Name</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="form.customer_name"
                  placeholder="Customer Name"
                />
                <small class="text-danger" v-if="errors.customer_name">{{
                  errors.customer_name[0]
                }}</small>
              </div>
              <div class="col-sm-4">
                <label>Table Name</label>
                <select v-model="form.place_id" class="form-control">
                  <option
                    v-for="(place, i) in places"
                    :key="i"
                    :value="place.id"
                  >
                    {{ place.name }} - {{ place.venue_name }}
                  </option>
                </select>
              </div>
              <text-input
                :value.sync="orderTime"
                label="Order Time"
                classes="form-group col-sm-4"
                disabled="disabled"
              ></text-input>
            </div>
            <div class="btn btn-primary" @click="toggleCamera">Member</div>
            <table class="table table-striped mt-4">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cart, index) in form.carts" :key="index">
                  <td>
                    <v2-select
                      v-model="cart.id"
                      :options="products"
                      :reduce="(products) => products.id"
                      label="name"
                    >
                    </v2-select>
                    <small class="text-danger" v-if="errors.product_id">{{
                      errors.product_id[0]
                    }}</small>
                  </td>
                  <td>
                    <input
                      type="number"
                      v-model="cart.count"
                      placeholder="Enter Quantity"
                      class="form-control"
                      min="1"
                    />
                  </td>
                  <td>
                    <div v-if="index > 0">
                      <a
                        type="button"
                        @click="deleteFind(index)"
                        class="btn btn-danger w-100"
                      >
                        Delete
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-7">
                <a type="button" @click="addFind()" class="btn btn-success">
                  New Product
                </a>
              </div>
              <!-- <div class="col">
                    <div class="invoice-detail-item">
                      <div class="invoice-detail-name">Total</div>
                      <h3 class="font-weight-bold invoice-detail-value-lg">
                        Rp. {{ total.toLocaleString() }}
                      </h3>
                    </div>
                  </div> -->
            </div>
            <div class="row">
              <div class="col-sm-4 mt-4">
                <label for="">Payment</label>
                <select v-model="payment" class="form-control" name="" id="">
                  <option value="pay-now">Pay Now</option>
                  <option value="pay-later">Pay Later</option>
                </select>
              </div>
            </div>
            <StreamBarcodeReader
              v-if="camera"
              class="w-50 mt-3"
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
            <div class="row mt-3">
              <div class="col-12">
                <div
                  class="row mb-5"
                  v-for="cart in carts"
                  :key="cart.product_id"
                >
                  <div
                    class="col-12 pt-2 pb-2"
                    style="
                      border-top: 1px #c8c8c8 solid;
                      border-bottom: 1px #c8c8c8 solid;
                    "
                  >
                    <div class="row">
                      <div
                        class="col-auto"
                        :style="{
                          backgroundImage: `url(${cart.image})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          minWidth: '120px',
                        }"
                      ></div>
                      <div class="col-7">
                        <h5 class="font-weight-light">{{ cart.name }}</h5>
                        <div class="font-italic">{{ cart.price }}</div>
                        <button
                          class="badge badge-primary my-2"
                          data-toggle="modal"
                          :data-target="`#exampleModal-${cart.id}`"
                        >
                          Edit Cart
                        </button>
                        <div
                          class="py-1 w-100"
                          v-for="(item, indexItem) in cart.additionals"
                          :key="indexItem"
                          style="
                            border-top: 1px #c8c8c8 solid;
                            border-bottom: 1px #c8c8c8 solid;
                          "
                        >
                          <div v-for="additional in item" :key="additional.id">
                            <div v-if="additional.count != 0">
                              {{ additional.count }} -
                              {{ additional.product_name }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col text-right align-self-center">
                        <div class="row justify-content-end">
                          <button
                            class="btn btn-primary mx-2"
                            @click="decreaseProductCount($event, cart.id)"
                          >
                            -
                          </button>
                          <input
                            type="text"
                            inputmode="numeric"
                            class="form-control text-center d-inline-block"
                            style="width: 5em"
                            v-model="cart.count"
                            min="0"
                          />
                          <button
                            class="btn btn-primary mx-2"
                            @click="increaseProductCount($event, cart.id)"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group w-100">
                <div class="row justify-content-end">
                  <button class="btn btn-danger col-2" @click="resetCart">
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary col-2 ml-4 mr-5"
                    @click="submitPurchase"
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card card-body">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="">Date</label>
                      <input
                        type="date"
                        v-model="filter.date"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Status</label>
                      <select v-model="filter.status" class="form-control">
                        <option value="">Select Status</option>
                        <option
                          v-for="(status, index) in cashier_status"
                          :key="index"
                          :value="index"
                        >
                          {{ status }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Venue</label>
                      <select class="form-control" v-model="filter.venue_id">
                        <option value="">Select Venue</option>
                        <option
                          :value="venue.id"
                          v-for="venue in venues"
                          :key="venue.id"
                        >
                          {{ venue.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Payment Type</label>
                      <select
                        class="form-control"
                        v-model="filter.payment_type_id"
                      >
                        <option value="">Select Payment Type</option>
                        <option
                          :value="payment_type.id"
                          v-for="payment_type in payment_types"
                          :key="payment_type.id"
                        >
                          {{ payment_type.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary w-100 mt-1" @click="getData">
                      Filter Order
                    </button>
                  </div>
                </div>
              </div>
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="cashier"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ dateFormat(row.item.created_at) }}
                    </td>
                    <td>
                      {{ row.item.customer_name }}
                    </td>
                    <td>{{ row.item.payment_type_name }}</td>
                    <td>{{ row.item.place_name }}</td>
                    <td>{{ row.item.venue_name }}</td>
                    <td>Rp. {{ formatPrice(row.item.total_price) }}</td>
                    <td>{{ row.item.status }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'CashierEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-success mr-1"
                        >Show</router-link
                      >
                      <button
                        @click="deleteCashier(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="lastPage"
                    :total-visible="itemsPerPage"
                    @input="getData"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import moment from 'moment';
import {
  OrderRepository,
  ProfileRepository,
  ProductRepository,
} from '../../repositories/RepositoryFactory';
import axios from 'axios';
import swal from 'sweetalert';
import { VenueRepository } from '../../repositories/RepositoryFactory';
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default {
  name: 'Cashier',
  components: { StreamBarcodeReader },
  data() {
    return {
      payment: 'pay-now',
      orderTime: new Date().toDateString(),
      products: [],
      product_id: '',
      carts: [],
      places: [],
      search: '',
      page: 1,
      lastPage: 0,
      pageCount: 0,
      itemsPerPage: 10,
      name: 'Cashier',
      cashier: [],
      cashier_status: [],
      payment_types: [],
      loading: false,
      venues: [],
      filter: {
        date: new Date().toISOString().slice(0, 10),
        status: '',
        venue_id: '',
        payment_type_id: '',
      },
      form: {
        customer_name: '',
        place_id: '',
        carts: [
          {
            id: '',
            count: 1,
          },
        ],
      },
      errors: [],
      camera: false,
    };
  },
  mounted() {
    this.getProducts();
    this.getData();
    this.getVenue();
    this.getPaymentType();
    this.getTables();
    ProfileRepository.get().then(({ data }) => {
      if (data.data.venue_id) {
        this.form.venue_id = data.data.venue_id;
      }
    });
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('LLL');
    },
    addFind() {
      this.form.carts.push({
        id: '',
        count: 1,
      });
    },
    deleteFind(index) {
      this.form.carts.splice(index, 1);
    },
    getProducts() {
      ProductRepository.getAll({
        venue_id: 1,
      })
        .then(({ data }) => {
          this.products = data.data;
        })
        .catch((err) => {
          swal('Error!', err.response.data.message, 'error');
        });
    },
    getTables() {
      axios
        .get(this.$store.state.api + 'place/all', {
          params: {
            venue_id: this.form.venue_id,
          },
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(({ data }) => {
          this.places = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetCart() {
      this.form = {
        customer_name: '',
        place_id: '',
        carts: [
          {
            id: '',
            count: '',
          },
        ],
      };
    },
    toggleCamera() {
      this.camera = !this.camera;
    },
    focusForm() {
      this.$refs.formCustomerOrder.scrollIntoView();
    },
    focusProducts() {
      this.$refs.formProducts.scrollIntoView();
    },
    deleteCashier(id) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this imaginary file!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + 'cashier/' + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.cashier = this.cashier.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal('Error!', err.message, 'error');
            });
          swal('Poof! Your product type file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your product type file is safe!');
        }
      });
    },
    submitPurchase() {
      if (this.form.carts.some((item) => item.id == '' || item.count == '')) {
        swal('Error!', 'Product cannot be empty', 'error');
      } else if (this.payment == '') {
        swal('Error!', 'Payment must be selected', 'error');
      } else {
        OrderRepository.create(this.form)
          .then(({ data }) => {
            this.resetCart();
            this.$noty.success('Ordering has been made');
            if (this.payment == 'pay-later') {
              this.getData();
            } else {
              this.$router.push({
                name: 'CashierEdit',
                params: { id: data.data.order.payment_order_id },
              });
            }
          })
          .catch((err) => {
            this.errors = err.response?.data;
            swal('Error!', err.response.data?.message, 'error');
          });
      }
    },
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'cashier', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            date: this.filter.date,
            status: this.filter.status,
            venue_id: this.filter.venue_id,
            payment_type_id: this.filter.payment_type_id,
          },
        })
        .then((result) => {
          const res = result.data.data.orders;
          this.loading = false;
          this.cashier = res.data;
          this.itemsPerPage = res.per_page;
          this.lastPage = res.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(this.$store.state.api + 'cashier/get_status', {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.loading = false;
          this.cashier_status = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVenue() {
      VenueRepository.get().then((result) => (this.venues = result.data.data));
    },
    getPaymentType() {
      axios
        .get(this.$store.state.api + 'payment_type', {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.payment_types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Date',
          value: 'created_at',
        },
        {
          text: 'Name',
          value: 'customer_name',
        },
        {
          text: 'Payment Type',
          value: 'payment_type_name',
        },
        {
          text: 'Table Number',
          value: 'table_name',
        },
        {
          text: 'Venue Name',
          value: 'venue_name',
        },
        {
          text: 'Price',
          value: 'total_price',
        },
        {
          text: 'Status',
          value: 'status',
        },
        { text: 'Action' },
      ];
    },
  },
};
</script>

<style scoped>
.btn-success,
.btn-success.disabled {
  color: white !important;
}
</style>
