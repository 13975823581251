import Cashier from './Cashier.vue'
import CashierCreate from './Create.vue'
import CashierEdit from './Create.vue'

export default [
    {
        path: '/cashier',
        name: 'Cashier',
        component: Cashier
    },
    {
        path: '/cashier/create',
        name: 'CashierCreate',
        component: CashierCreate
    },
    {
        path: '/cashier/edit/:id',
        name: 'CashierEdit',
        component: CashierEdit
    },
]
